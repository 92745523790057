import { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import Link from 'components/Link';
import { Button } from 'components/Button';
import DecoderText from 'components/DecoderText';
import Divider from 'components/Divider';
import Image from 'components/Image';
import Section from 'components/Section';
import ProfileImg from 'assets/Quadato.png';
import ProfileImgLarge from 'assets/Quadato.png';
import ProfileImgPlaceholder from 'assets/Quadato.png';
import { reflow } from 'utils/transition';
import { media } from 'utils/style';
import { ReactComponent as KatakanaProfile } from 'assets/katakana-profile.svg';
import Heading from 'components/Heading';
import Text from 'components/Text';
import './Profile.css';

const ProfileText = ({ status, titleId }) => (
  <Fragment>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="Why choose Quadato? " start={status !== 'exited'} delay={500} />
      
    </Heading>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      Andar Q? Bahar Q? Aise Q? Waise Q? Ye Mask Q? Ye Thali Q? ye Diye Q? hum baithe gharpe abtak-Q?
       Phatake jalane toh Q? ya na toh na Q? Trump office chodta nahi Q?

{' '}
      <Link href="https://www.qwilr.com">Q</Link>. next <Link href="/uses">uses page</Link>.
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      Hoof! We've got a lot of questions and as humans our curiosity is Endless! With these endless 
      possibilities come boundless expectations of imagination and overwhelming creativity; to solve problems... But itna complication Q?
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      We're a group of creative people, who WILL, whatever it takes to birth the ideas that fulfill your requirements to launch your brand and when asked, Aapke logo hai aisa Q? We'll have answered them for you... Toh ab waiting Q? Go through our portfolio on the website and tell us how you want it... It being a website, perhaps a social media strategy, a cover for your podcast, your online presence is now ours...
       ad videos, digital marketing, 3D visualisation and much more*, chahe jo bhi requirement ho, hum nahi puchenge Q?🎉.
    </Text>
  </Fragment>
);

const Profile = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="profile__content">
            <div className="profile__column">
              <ProfileText status={status} titleId={titleId} />
              <Button
                secondary
                className={classNames('profile__button', `profile__button--${status}`)}
                href="/contact"
                icon="send"
              >
                Contact Us
              </Button>
            </div>
            <div className="profile__column">
              <div className="profile__tag" aria-hidden>
                <Divider
                  notchWidth="64px"
                  notchHeight="8px"
                  collapsed={status !== 'entered'}
                  collapseDelay={1000}
                />
                <div
                  className={classNames(
                    'profile__tag-text',
                    `profile__tag-text--${status}`
                  )}
                >
                  YO!!!
                </div>
              </div>
              <div className="profile__image-wrapper">
                <Image
                  reveal
                  delay={100}
                  placeholder={ProfileImgPlaceholder}
                  srcSet={`${ProfileImg} 480w, ${ProfileImgLarge} 960w`}
                  sizes={`(max-width: ${media.mobile}px) 100vw, 480px`}
                  alt="Quadato Studios Logo"
                />
               
              </div>
            </div>
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Profile;
